
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import "@/pdfjs-dist/es5/build/pdf";
// FIX typings
// @ts-ignore
import * as pdfApp from "@/pdfjs-dist/lib/web/app";
// @ts-ignore
import { AppOptions } from "@/pdfjs-dist/lib/web/app_options";
import "@/pdfjs-dist/lib/web/genericcom";
import "@/pdfjs-dist/lib/web/pdf_print_service";
import "@/pdfjs-dist/build/pdf.worker.entry";
import "@/sass/index.scss";
import { ToolbarConfig, Theme, ToolbarIdConfig, PageScale } from "@/types";
import getAppConfig from "@/utils/pdf-config";
import { PDF_FILE_INPUT_ID } from "@/utils/constants";
import locale from "@/utils/locale";
import { getToolbarConfigValue, toolbarConfig } from "@/utils/toolbar-config";

if (AppOptions) {
  AppOptions.set("defaultUrl", null);
}

const themeCacheKey = "vue-pdf-app-theme";
const errorHandler = console.error.bind(console);

// pdf_print_service reassigns window.print.
// Assign original window.print on component destroy.
// Once pdf is opened again assign window.print = pdfjs.print
const pdfPrint = window.print.bind(window);
window.print = (window as any).__nativePrint__ || pdfPrint;

@Component
export default class PdfViewer extends Vue {
  @Prop({ required: false, default: () => toolbarConfig, type: Object })
  readonly config!: ToolbarConfig;

  @Prop({ required: false, type: Boolean, default: () => false })
  readonly title!: boolean;

  // can accept string URL
  @Prop({ required: false, type: [String, ArrayBuffer] })
  readonly pdf?: string | ArrayBuffer;

  @Prop({ required: false, type: String })
  readonly theme?: Theme;

  @Prop({ required: false, type: String })
  readonly fileName?: string;

  @Prop({ required: false, type: Object })
  readonly idConfig?: ToolbarIdConfig;

  @Prop({ required: false, type: [Number, String] })
  readonly pageScale?: PageScale;

  @Prop({ required: false, type: Number })
  readonly pageNumber?: number;

  private defaultLocale = JSON.stringify(locale);

  private isOpenHandlerBinded = false;

  private isSidebarHidden = true;

  private isFindbarHidden = true;

  private cacheTheme = window.localStorage.getItem(
    themeCacheKey
  ) as Theme | null;

  private get isSidebarToolbarHidden() {
    const isCustomToolbar =
      this.idConfig?.viewAttachments &&
      this.idConfig?.viewOutline &&
      this.idConfig?.viewThumbnail;

    return isCustomToolbar || !this.config.sidebar;
  }

  private get isToolbarHidden() {
    if (this.config.toolbar === false) return "zero-top";
    return "";
  }

  private get localTheme(): Theme {
    if (this.theme) return this.theme;

    if (this.cacheTheme) return this.cacheTheme;

    const prefersTheme = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("content")
      .replace(/"/g, "") as Theme;
    if (["light", "dark"].includes(prefersTheme)) return prefersTheme;

    return "dark";
  }

  private get slotProps() {
    return {
      toggleTheme: this.toggleTheme,
      isSidebarHidden: this.isSidebarHidden,
      isFindbarHidden: this.isFindbarHidden,
    };
  }

  private beforeDestroy() {
    this.destroyPdf();
  }

  private created() {
    window.print = pdfPrint;
    pdfApp.PDFViewerApplication.isViewerEmbedded = !this.title;
    this.$emit("after-created", pdfApp.PDFViewerApplication);
  }

  private mounted() {
    this.addPrintContainer();
    const config = getAppConfig(this.idConfig);

    if (pdfApp.PDFViewerApplication) {
      pdfApp.PDFViewerApplication.run(config);
      pdfApp.PDFViewerApplication.initializedPromise
        .then(this.setDefaultPageScale.bind(this))
        .then(this.open.bind(this))
        .then(this.bindSidebarToggleEvents.bind(this))
        .then(this.bindFindbarToggleEvents.bind(this))
        .catch(errorHandler);
    }
  }

  private bindSidebarToggleEvents() {
    const config = getAppConfig(this.idConfig);
    const toggleButton = config.sidebar.toggleButton;
    const handler = this.checkSidebarVisibility.bind(this);

    toggleButton?.addEventListener("click", handler);
    this.$once("hook:beforeDestroy", () => {
      toggleButton?.removeEventListener("click", handler);
    });
  }

  private bindFindbarToggleEvents() {
    const config = getAppConfig(this.idConfig);
    const toggleButton = config.findBar.toggleButton;
    const handler = this.checkFindbarVisibility.bind(this);

    toggleButton?.addEventListener("click", handler);
    this.$once("hook:beforeDestroy", () => {
      toggleButton?.removeEventListener("click", handler);
    });
  }

  private bindOpenHandler() {
    if (this.isOpenHandlerBinded) return;

    const fileInput = document.getElementById(PDF_FILE_INPUT_ID);
    const fileInputHandler = async () => {
      // @ts-ignore
      await pdfApp.PDFViewerApplication.pdfLoadingTask?.promise;
      this.openDocument();
    };

    fileInput?.addEventListener("change", fileInputHandler);
    this.$once("hook:beforeDestroy", () => {
      fileInput?.removeEventListener("change", fileInputHandler);
    });

    this.isOpenHandlerBinded = true;
  }

  private open() {
    this.clearCacheTimeout();
    if (!pdfApp.PDFViewerApplication) return;

    if (!this.pdf) {
      pdfApp.PDFViewerApplication.close();
    } else {
      pdfApp.PDFViewerApplication.open(this.pdf)
        .then(() => {
          // @ts-ignore
          return pdfApp.PDFViewerApplication.pdfDocument?.getMetadata();
        })
        .then((fileMetadata: { contentDispositionFilename: null | string }) => {
          // @ts-ignore
          pdfApp.PDFViewerApplication.contentDispositionFilename =
            this.fileName || fileMetadata.contentDispositionFilename;
        })
        .then(this.openDocument.bind(this))
        .catch(errorHandler);
    }
  }

  private async openDocument() {
    this.resetLoadingBar();
    this.$emit("open", pdfApp.PDFViewerApplication);

    // @ts-ignore
    if (pdfApp.PDFViewerApplication?.pdfViewer?.pagesPromise) {
      // @ts-ignore
      await pdfApp.PDFViewerApplication.pdfViewer.pagesPromise.catch(
        errorHandler
      );

      if (this.pageNumber) {
        setTimeout(() => (pdfApp.PDFViewerApplication.page = this.pageNumber));
      }

      this.checkSidebarVisibility();
      this.checkFindbarVisibility();
      this.$emit("pages-rendered", pdfApp.PDFViewerApplication);
    }
  }

  private checkSidebarVisibility() {
    const sidebar = pdfApp.PDFViewerApplication?.pdfSidebar;
    // @ts-ignore
    this.isSidebarHidden = !(sidebar && sidebar.isOpen);
  }

  private checkFindbarVisibility() {
    // @ts-ignore
    const findbar = pdfApp.PDFViewerApplication?.findBar;
    this.isFindbarHidden = !(findbar && findbar.opened);
  }

  private addPrintContainer() {
    const printElId = "printContainer";
    const el = document.createElement("div");
    el.id = printElId;
    document.body.appendChild(el);

    const styleEl = document.createElement("style");
    styleEl.type = "text/css";
    styleEl.innerHTML = `
        @media print {
          body > *:not(#printContainer) {
            display: none !important; 
        }
      }`;
    document.head.appendChild(styleEl);

    this.$once("hook:beforeDestroy", () => {
      document.body.removeChild(el);
      document.head.removeChild(styleEl);
    });
  }

  private destroyPdf(): void {
    this.clearCacheTimeout();
    pdfApp.PDFViewerApplication.unbindEvents();
    pdfApp.PDFViewerApplication.unbindWindowEvents();

    // @ts-ignore
    pdfApp.PDFViewerApplication.pdfDocument?.destroy();

    const el = document.getElementById(PDF_FILE_INPUT_ID);
    el && el.remove();

    // __nativePrint__ is assigned in pdf_print_service.js
    window.print = (window as any).__nativePrint__ || window.print;
  }

  private toggleTheme() {
    const newTheme = this.localTheme === "dark" ? "light" : "dark";
    this.$emit("update:theme", newTheme);
    this.cacheTheme = newTheme;
    window.localStorage.setItem(themeCacheKey, newTheme);
  }

  private clearCacheTimeout() {
    const cacheTimeoutId =
      // @ts-ignore
      pdfApp.PDFViewerApplication.pdfRenderingQueue?.idleTimeout;
    clearTimeout(cacheTimeoutId);
  }

  private getScale(value: number): string {
    return `{ "scale": ${value} }`;
  }

  private showElem(
    defaultToolbarPath: string,
    customToolbarElem?: keyof ToolbarIdConfig
  ): boolean {
    if (customToolbarElem && this.idConfig) {
      return !this.idConfig[customToolbarElem];
    }

    return !(getToolbarConfigValue(this.config, defaultToolbarPath) === false);
  }

  private setDefaultPageScale() {
    this.pageScale && AppOptions.set("defaultZoomValue", this.pageScale);
  }

  private resetLoadingBar() {
    pdfApp.PDFViewerApplication.loadingBar.show();
    pdfApp.PDFViewerApplication.loadingBar.percent = 0;
  }

  @Watch("pdf")
  private pdfChangeHandler() {
    this.open();
  }
}
